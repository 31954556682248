
import { defineComponent, PropType } from "vue";
import { IconoRedesSociales } from "@ritttzy/vue-icons";

type contacts =
  | "web"
  | "user"
  | "click"
  | "address"
  | "facebook"
  | "phone"
  | "tiktok"
  | "twitter"
  | "location"
  | "mail"
  | "pinterest"
  | "whatsapp"
  | "youtube"
  | "instagram";

export default defineComponent({
  components: {
    IconoRedesSociales,
  },
  props: {
    contact: {
      type: String as PropType<contacts>,
      default: "web",
    },
    target: {
      type: String,
      default: "https://ritttzy.com",
    },
    text: {
      type: String,
      default: "ritttzy.com",
    },
    custom: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    realTarget(): string {
      if (this.contact == "phone") return `tel:${this.target}`;
      else if (this.contact == "mail") return `mailto:${this.target}`;
      else if (this.contact == "whatsapp")
        return `https://api.whatsapp.com/send?phone=${this.target}`;
      else return this.target;
    },
  },
});
