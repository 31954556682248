
import { defineComponent } from "vue";
import { IconoMasMenos } from "@ritttzy/vue-icons";
export default defineComponent({
  name: "questions",
  props: {
    question: {
      type: String,
      default: "¿Cual es tu pregunta?",
    },
    answer: {
      type: String,
      default: "Esta es tu respuesta.",
    },
    complex: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IconoMasMenos,
  },
  inheritAttrs: false,
  data() {
    return {
      active: false,
    };
  },
  methods: {
    activate() {
      this.active = !this.active;
    },
  },
});
