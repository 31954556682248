import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "contactcard-cont" }
const _hoisted_3 = { class: "contactcard-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconoRedesSociales = _resolveComponent("IconoRedesSociales")!

  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.realTarget,
    class: "contactcard-link"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IconoRedesSociales, {
        icon: _ctx.contact,
        class: "contactcard-icon"
      }, null, 8, ["icon"]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1)
    ])
  ], 8, _hoisted_1))
}