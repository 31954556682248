
import { defineComponent, PropType } from "vue";
type targetsType = string[];

export default defineComponent({
  name: "TopMenu",
  props: {
    type: {
      //the type of element, container or link, default is link
      type: String,
      default: "link",
    },
    active: {
      //wether the menu is currently active or not
      type: Boolean,
      default: false,
    },
    activeId: {
      //the active element in the menu
      type: String,
      default: "inicio",
    },
    scrolled: {
      //wether the page has past the menu height
      type: Boolean,
      default: false,
    },
    breakpoint: {
      //the breakpoint in pixels where the menu start displaying the button to show the items
      type: Number,
      default: 768,
    },
    target: {
      //if its an item that activates with the scroll, this is the id of the element where it points
      type: Array as PropType<targetsType>,
      default: () => [],
    },
    scrollOffset: {
      //the offset that exists in case the menu is fixed, the space behind the menu that dplaces the content
      type: Number,
      default: 0,
    },
    menuId: {
      //id of the html element that contains the menu
      type: String,
      default: "menu",
    },
    toggleLeft: {
      //wether show the toggle button left instead of right
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "update:active",
    "update:activeId",
    "update:scrolled",
    "update:scrollOffset",
  ],
  data() {
    return {
      //wether the menu is below the breakpoint or not
      mobileSize: window.innerWidth < this.breakpoint,
      //poitions of the target elements
      targetPos: [0],
      //poitions of the target elements
      targetIds: [""],
      //wether the scroll is past the menu or not
      scrolledMenu: false,
    };
  },
  methods: {
    activateMenu(op = true) {
      //toggle the menu active property
      //if op is true, then toggle, if its false always deactivate
      //set the active menu property to true
      if (op) {
        //toggle the menu active prop
        this.$emit("update:active", !this.active);
      } else {
        //deactivate the menu
        this.$emit("update:active", false);
      }
    },
    isMobileSize() {
      //check if the window is below the breakpoint
      //if it is set the flag to true, if its not deactivate the menu and set the flag to false
      if (window.innerWidth <= this.breakpoint) {
        this.mobileSize = true;
      } else {
        this.mobileSize = false;
        this.$emit("update:active", false);
      }
    },
    preparescroll() {
      //prepare the array of target positions
      //reset the target
      this.targetPos = [];
      this.targetIds = [];
      //check in every target id the position of itself and susbstract the offset
      this.target.forEach((id) => {
        let elem = document.getElementById(id);
        if (elem) {
          this.targetPos.push(elem.offsetTop - this.scrollOffset);
          this.targetIds.push(id);
        }
      });
    },
    activeScroll() {
      //check which of the targeted elements is active
      //get the scroll position of the page
      let actualPos = window.pageYOffset;
      //size of the found targeted elements
      var size = this.targetPos.length;
      //check for every element from the beggining
      for (var i = 0; i < size; i++) {
        //if the actual conter is the last one
        if (i == size - 1) {
          //if the position is higher than the last one registered then return the last one as the active item
          if (actualPos >= this.targetPos[i]) {
            this.$emit("update:activeId", this.targetIds[i]);
          }
        } else {
          //if the actual position is between the actual item and the next one, return the actual
          if (
            actualPos >= this.targetPos[i] &&
            actualPos < this.targetPos[i + 1]
          ) {
            this.$emit("update:activeId", this.targetIds[i]);
            return;
          }
        }
      }
    },
    isScrolled() {
      //check if the scroll is beyond the menu height
      //get the menu element
      let elem = document.getElementById(this.menuId);
      //if the element exists compare the scroll position with the height of the element
      if (elem) {
        //return the comparison of the scroll with the height
        this.$emit("update:scrolled", window.pageYOffset >= this.scrollOffset);
      }
    },
    getOffset() {
      //get the offset of the pge according to the menu height
      //search for the menu element
      let elem = document.getElementById(this.menuId);
      //if the element exists emit the offset
      if (elem) {
        this.$emit("update:scrollOffset", elem.offsetHeight);
      }
    },
  },
  created() {
    if (this.type == "cont") {
      window.addEventListener("load", this.isMobileSize);
      window.addEventListener("load", this.preparescroll);
      window.addEventListener("load", this.activeScroll);
      window.addEventListener("load", this.isScrolled);
      window.addEventListener("load", this.getOffset);
      window.addEventListener("resize", this.getOffset);
      window.addEventListener("resize", this.isMobileSize);
      window.addEventListener("resize", this.preparescroll);
      window.addEventListener("scroll", this.activeScroll);
      window.addEventListener("scroll", this.isScrolled);
    }
  },
});
