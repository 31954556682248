
import { defineComponent } from "vue";

export default defineComponent({
  name: "TeamCard",
  props: {
    name: {
      type: String,
      default: "person",
    },
    title: {
      type: String,
      default: "persona",
    },
    description: {
      type: String,
      default: "persona",
    },
    photoUrl: {
      type: String,
      default: "img/photo.jpg",
    },
    photoAlt: {
      type: String,
      default: "a photo",
    },
  },
});
