
import { defineComponent } from "vue";
import { IconoRedesSociales } from "@ritttzy/vue-icons";
import { doFetch } from "@ritttzy/core";
export default defineComponent({
  name: "ContactUsForm",
  components: {
    IconoRedesSociales,
  },
  data() {
    return {
      delivered: false,
      disabled: false,
      formData: { name: "", phone: "", mail: "", message: "" },
    };
  },
  props: {
    target: {
      type: String,
      default: "",
    },
    deliveredMessage: {
      type: String,
      default: "Formulario enviado correctamente.",
    },
  },
  methods: {
    async deliver() {
      //disable the
      this.disabled = true;
      if (this.target == "") {
        this.disabled = false;
        return;
      }
      let response = await doFetch(this.target, "POST", this.formData);
      //console.log(response);
      if (!response.ok) {
        alert("Error enviando el formulario");
        this.disabled = false;
        return;
      }
      this.delivered = true;
      this.disabled = true;
    },
  },
});
