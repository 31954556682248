<template>
  <div v-bind="$attrs" class="section-container">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Section",
};
</script>
<style>
.section-container {
  padding: 40px 15px;
}

.section-container > div.section {
  margin: auto;
  max-width: 1024px;
}

.section-container:after {
  content: "";
  display: table;
  clear: both;
}
</style>
