import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "teamcard-cont" }
const _hoisted_2 = { class: "teamcard-photo-cont" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "teamcard-extra-cont" }
const _hoisted_5 = { class: "teamcard-name" }
const _hoisted_6 = { class: "teamcard-title" }
const _hoisted_7 = { class: "teamcard-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.photoUrl,
        alt: _ctx.photoAlt,
        class: "teamcard-photo"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.name), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.description), 1),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}