import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type == 'link')
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "topmenu-item",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activateMenu(false)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["topmenu-container", {
      active: _ctx.active,
      inactive: !_ctx.active,
      responsive: _ctx.mobileSize,
    }])
      }, [
        _renderSlot(_ctx.$slots, "default"),
        _withDirectives(_createElementVNode("a", {
          href: "javascript:void(0);",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.activateMenu && _ctx.activateMenu(...args))),
          class: _normalizeClass(["topmenu-toggle", {
        'topmenu-toggle-left': _ctx.toggleLeft,
        'topmenu-toggle-right': !_ctx.toggleLeft,
      }])
        }, "☰", 2), [
          [_vShow, _ctx.mobileSize]
        ])
      ], 2))
}